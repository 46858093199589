#firepad {
  width: 100%;
  height: 81vh;
  border-right: 1px solid #d3d3d3;
  border-left: 1px solid #d3d3d3;
  margin-top: -16px;
}

a.powered-by-firepad {
  display: none;
}
